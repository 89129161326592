import { Route, Routes } from "react-router-dom"

import Homepage from "./root/paginas/homepage"
import Colecoes from "./root/paginas/colecoes"
import Paginas from "./root/paginas/paginas"
import Contas from "./root/paginas/admin/contas"
import Transcricao from "./root/paginas/transcricao"
import NotFound from "./root/paginas/404"

function App() {
  return (
    <main>
      <Routes>
        <Route path='/' element={<Homepage />} />

        <Route path='/coleções' element={<Colecoes />} exact />
        <Route path='/colecoes/livro/:id/:nome/:escrivao' element={<Paginas />} />
        <Route path='/colecoes/livro/:id/:nome/:escrivao/página/:id' element={<Transcricao />} />

        <Route path='/admin/contas' element={<Contas />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </main>
  );
}

export default App;
