import React, { useEffect, useState } from 'react'
import { IoPersonCircleSharp } from 'react-icons/io5'
import { PiPlusBold, PiTrash, PiTrashBold } from 'react-icons/pi'
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import Header from '../../interface/header'
import MenuT from '../../interface/menuT'
import Cadastro from './cadastar'
import Deletar from './deletar_conta'
import { BsPersonPlus, BsPersonPlusFill } from 'react-icons/bs'

const initialcontas = [
    { id: 1, nome: 'João', email: 'joao@example.com', plano: 'Premium' },
    { id: 2, nome: 'Maria', email: 'maria@example.com', plano: 'Basic' },
    { id: 3, nome: 'Carlos', email: 'carlos@example.com', plano: 'Standard' },
    { id: 4, nome: 'Ana', email: 'ana@example.com', plano: 'Premium' },
    { id: 5, nome: 'Paulo', email: 'paulo@example.com', plano: 'Basic' },
    { id: 6, nome: 'Fernanda', email: 'fernanda@example.com', plano: 'Standard' },
    { id: 7, nome: 'Rafael', email: 'rafael@example.com', plano: 'Premium' },
    { id: 8, nome: 'Bianca', email: 'bianca@example.com', plano: 'Basic' },
    { id: 9, nome: 'Marcelo', email: 'marcelo@example.com', plano: 'Standard' },
    { id: 10, nome: 'Gabriela', email: 'gabriela@example.com', plano: 'Premium' },
    { id: 11, nome: 'Renato', email: 'renato@example.com', plano: 'Basic' },
    { id: 12, nome: 'Juliana', email: 'juliana@example.com', plano: 'Standard' },
    { id: 13, nome: 'Lucas', email: 'lucas@example.com', plano: 'Premium' },
    { id: 14, nome: 'Patrícia', email: 'patricia@example.com', plano: 'Basic' },
    { id: 15, nome: 'Eduardo', email: 'eduardo@example.com', plano: 'Standard' },
    { id: 16, nome: 'Amanda', email: 'amanda@example.com', plano: 'Premium' },
    { id: 17, nome: 'Rodrigo', email: 'rodrigo@example.com', plano: 'Basic' },
    { id: 18, nome: 'Cláudia', email: 'claudia@example.com', plano: 'Standard' },
    { id: 19, nome: 'Felipe', email: 'felipe@example.com', plano: 'Premium' },
    { id: 20, nome: 'Mariana', email: 'mariana@example.com', plano: 'Basic' },
    { id: 21, nome: 'Sérgio', email: 'sergio@example.com', plano: 'Standard' },
    { id: 22, nome: 'Vanessa', email: 'vanessa@example.com', plano: 'Premium' },
    { id: 23, nome: 'Alberto', email: 'alberto@example.com', plano: 'Basic' }
]

const Colecoes = () => {
    const [contas, setContas] = useState(initialcontas)
    const [searchTerm, setSearchTerm] = useState('')
    const [sortConfig, setSortConfig] = useState({ key: null, direcao: 'asc' })
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [modalCadastro, setModalCadastro] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    
    const [paginaAtual, setPaginaAtual] = useState(1)
    const [itensPorPagina, setItensPorPagina] = useState(0) 

    const recalcula = () => {
        setItensPorPagina((window.innerHeight - 350) / 40)
    }

    useEffect(() => {
       recalcula()

       window.addEventListener('resize', recalcula)

       return() => {
        window.removeEventListener('resize', recalcula)
       }
    },[])

    const contasFiltradas = contas.filter(user =>
        user.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.plano.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const totalPaginas = Math.ceil(contasFiltradas.length / itensPorPagina)
    const indiceInicial = (paginaAtual - 1) * itensPorPagina
    const itensPaginaAtual = contasFiltradas.slice(indiceInicial, indiceInicial + itensPorPagina)

    const ordenar = (key) => {
        let direcao = 'asc'
        if (sortConfig.key === key && sortConfig.direcao === 'asc') {
            direcao = 'desc'
        }
        setSortConfig({ key, direcao })

        const sortedcontas = [...contasFiltradas].sort((a, b) => {
            if (a[key] < b[key]) return direcao === 'asc' ? -1 : 1
            if (a[key] > b[key]) return direcao === 'asc' ? 1 : -1
            return 0
        })
        setContas(sortedcontas)
    }

    const getArrowColor = (key, direction) => {
        if (sortConfig.key === key) {
            return sortConfig.direcao === direction ? 'black' : 'gray'
        }
        return 'gray'
    }

    const toggleSelectAll = () => {
        setIsAllSelected(!isAllSelected)
        setContas(contas.map(user => ({ ...user, isSelected: !isAllSelected })))
    }

    const toggleBox = (id) => {
        setContas(contas.map(user => user.id === id ? { ...user, isSelected: !user.isSelected } : user))
    }

    const paginaAnterior = () => {
        if (paginaAtual > 1) setPaginaAtual(paginaAtual - 1)
    }

    const proximaPagina = () => {
        if (paginaAtual < totalPaginas) setPaginaAtual(paginaAtual + 1)
    }

    return (
        <div className='flex flex-col h-[100vh]'>
            {modalCadastro &&
                <Cadastro 
                    setModalCadastro={() => {setModalCadastro()}}
                />
            }
            {modalDelete &&
                <Deletar 
                    setModalDeletar={() => {setModalDelete()}}
                />
            }
            <div className='flex justify-between w-full items-center'>
                <Header/>
                <div className='mr-5'>
                    <IoPersonCircleSharp size={50}/>
                </div>
            </div>
            <MenuT
                rotas={[{ nome: 'Coleções', link: '/coleções' }, { nome: 'Contas', link: '/admin/contas' }]}
                botoes={[]}
            />
            <div className='flex gap-3 w-full px-5 pt-5 pb-5 '>
                <div className='flex items-center px-2 border border-black rounded-lg w-full'>
                    <input
                        type="text"
                        placeholder="Buscar usuário..."
                        className="w-full p-2 focus:outline-none"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className='flex gap-3'>
                    <button onClick={() => {setModalCadastro(true)}} className='botao_1 flex items-center gap-2 justify-center '>
                        <BsPersonPlus size={25} color='white'/>
                    </button>
                    <button onClick={() => {setModalDelete(true)}} className='botao_1 flex items-center gap-2 justify-center '>
                        <PiTrash size={25} color='white'/>
                    </button>
                </div>
            </div>

            <div className=''>
                <table className='table-auto w-full'>
                    <thead className='border-b-2 border-b-gray-400 font-semibold text-gray-600 text-xl'>
                        <tr>
                            <th className='w-[3vw] py-2'>
                                <input
                                    className='h-5 w-5 custom-checkbox'
                                    type='checkbox'
                                    checked={isAllSelected}
                                    onChange={toggleSelectAll}
                                />
                            </th>
                            <th className='w-[5vw]'>
                                <div className='flex gap-2 items-center cursor-pointer' onClick={() => ordenar('id')}>
                                    <h1>ID</h1>
                                    <div>
                                        <BiSolidUpArrow size={15} color={getArrowColor('id', 'asc')} />
                                        <BiSolidDownArrow size={15} color={getArrowColor('id', 'desc')} />
                                    </div>
                                </div>
                            </th>
                            <th className='w-[30vw] cursor-pointer text-left pl-2'>
                                <div onClick={() => ordenar('nome')} className='flex gap-2 items-center cursor-pointer'>
                                    <h1>NOME</h1>
                                    <div>
                                        <BiSolidUpArrow size={15} color={getArrowColor('nome', 'asc')} />
                                        <BiSolidDownArrow size={15} color={getArrowColor('nome', 'desc')} />
                                    </div>
                                </div>
                            </th>
                            <th className='w-[30vw] cursor-pointer text-left pl-2'>
                                <div onClick={() => ordenar('email')} className='flex gap-2 items-center cursor-pointer'>
                                    <h1>EMAIL</h1>
                                    <div>
                                        <BiSolidUpArrow size={15} color={getArrowColor('email', 'asc')} />
                                        <BiSolidDownArrow size={15} color={getArrowColor('email', 'desc')} />
                                    </div>
                                </div>
                            </th>
                            <th className='w-[15vw] cursor-pointer'>
                                <div onClick={() => ordenar('plano')} className='flex gap-2 items-center justify-center cursor-pointer'>
                                    <h1>PLANO</h1>
                                    <div>
                                        <BiSolidUpArrow size={15} color={getArrowColor('plano', 'asc')} />
                                        <BiSolidDownArrow size={15} color={getArrowColor('plano', 'desc')} />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border-b border-b-gray-400 bg-gray-300 font-semibold text-gray-600'>
                        {itensPaginaAtual.map(user => (
                            <tr key={user.id} >
                                <td className='text-center py-2'>
                                    <input
                                        className='h-5 w-5 custom-checkbox'
                                        type='checkbox'
                                        checked={!!user.isSelected}
                                        onChange={() => toggleBox(user.id)}
                                    />
                                </td>
                                <td className='text-left'>{user.id}</td>
                                <td className='text-left pl-2'>{user.nome}</td>
                                <td className='text-left pl-2'>{user.email}</td>
                                <td className='text-center'>{user.plano}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className='flex justify-between items-center py-3 px-5 border-t border-gray-400 font-semibold text-gray-600'>
                <span>
                    {itensPaginaAtual.length} de {contasFiltradas.length}
                </span>
                <div className='flex gap-2 items-center'>
                    <button 
                        className='botao_1'
                        onClick={paginaAnterior}
                        disabled={paginaAtual === 1}
                    >
                        <MdOutlineKeyboardArrowLeft size={30} />
                    </button>
                    <span className='flex'>{paginaAtual} / {totalPaginas}</span>
                    <button 
                        className='botao_1'
                        onClick={proximaPagina}
                        disabled={paginaAtual === totalPaginas}
                    >
                        <MdOutlineKeyboardArrowRight size={30}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Colecoes
