import { useState } from 'react'
import '../../index.css'
import { PiDot } from 'react-icons/pi'

const Dashboard = ({ setModalCadastro, funcLogin }) => {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')
    const [showRequisitosSenha, setShowRequisitosSenha] = useState(false)
    const [erroSenha, setErroSenha] = useState('')
    const [shake, setShake] = useState(false)

    const cadastar = async () => {
        try {
            const response = await fetch('https://transcritor-ia.com:8086/api/v1/auth/registration/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    email: email,
                    password1: senha,
                    password2: confirmaSenha
                })
            })

            if (response.ok) {
                const resposta = await response.json()
                console.log(resposta)
                funcLogin(username, senha)
            }else{
                const resposta = await response.json()
                setErroSenha(resposta.password1)
                setShake(true)
                setTimeout(() => setShake(false), 500)
            }

        } catch (error) {
            console.error("Erro ao cadastrar")
        }
    }

    const validarSenha = (senha, confirmaSenha) => {
        if (senha.length > 7 && /\d/.test(senha)  && senha === confirmaSenha) {
            return true;
        }
        
        return false;
    }

    return (
        <main className='absolute h-full w-full flex items-center justify-center'>
            <div onClick={() => { setModalCadastro(false) }} className='fundo-desfocado'></div>
            <div className='flex flex-col items-center rounded-lg sombra bg-white min-w-[400px] w-[30%] max-h-[95%] z-20 overflow-auto'>
                <div className='flex items-center justify-center mb-5 border-b-2 border-black w-full'>
                    <h1 className='mt-7 mb-3 text-[40px] font-semibold'>Cadastrar-se</h1>
                </div>

                <div className='flex flex-col gap-2 w-[90%]'>
                    <h1>Nome</h1>
                    <input value={username} onChange={(e) => { setUsername(e.target.value) }} type='text' className='input_2' />

                    <h1>Email</h1>
                    <input value={email} onChange={(e) => { setEmail(e.target.value) }} type='email' className='input_2' />

                    <h1>Senha</h1>
                    <input value={senha} onChange={(e) => { setSenha(e.target.value) }} type='password' className='input_2 ' />
                    {erroSenha !== '' && 
                        erroSenha.map((erro) => (
                            <div key={erro} className='flex'>
                                <PiDot color='red'/>
                                <h1 className='text-xs text-red-400 '>{erro}</h1>
                            </div>
                        ))
                    }

                    <h1>Confirmar senha</h1>
                    <input value={confirmaSenha} onChange={(e) => { setConfirmaSenha(e.target.value) }} type='password' className='input_2' />
                </div>

                {validarSenha(senha, confirmaSenha) && username !== '' && email !== '' ? (
                    <div className={`relative flex items-center justify-center w-[95%] ${shake ? 'shake-animation':''} mt-3`}>
                        <button onClick={cadastar} className='botao_1 mb-10'>
                            <h1 className='text-2xl p-2'>Cadastrar-se</h1>
                        </button>
                    </div>
                ) : (
                    <div className={`relative flex items-center justify-center w-[95%] mt-3`} onMouseEnter={() => setShowRequisitosSenha(true)} onMouseLeave={() => setShowRequisitosSenha(false)}>
                        <button disabled className='botao_1 mb-10'>
                            <h1 className='text-2xl text-gray-500 p-2'>Cadastrar-se</h1>
                        </button>

                        {showRequisitosSenha && (
                            <div className="absolute start-5 -mt-40 p-3 bg-white border border-gray-300 rounded shadow-lg">
                                <p className="text-sm font-semibold">Sua senha deve conter:</p>
                                <ul className="list-disc ml-4 text-sm">
                                    <li>8 ou mais caracteres.</li>
                                    <li>Pelo menos 1 número.</li>
                                </ul>
                            </div>
                        )}
                    </div>
                )}

            </div>
        </main>
    )
}

export default Dashboard;
