import { useState } from 'react'

const Dashboard = ({setModalCadastro}) => {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [confrimaSenha, setConfirmaSenha] = useState('')

    return (
        <main className='absolute h-full w-full flex items-center justify-center'>
           <div onClick={() => {setModalCadastro(false)}} className='fundo-desfocado'></div>
           <div className='flex flex-col items-center rounded-lg sombra bg-white w-[30%] h-[95%] overflow-auto z-20'>
                <div className=' flex items-center justify-center mb-5 border-b-2 border-black w-full'>
                    <h1 className='mt-7 mb-3 text-[40px] font-semibold '>Adicionar Usuário</h1>
                </div>

                <div className='flex flex-col w-[90%]'>
                    <h1>Nome</h1>
                    <input value={username} onChange={(e) => {setUsername(e.target.value)}} type='text' className='input_2'></input>

                    <h1>Email</h1>
                    <input value={email} onChange={(e) => {setEmail(e.target.value)}} type='email' className='input_2'></input>

                    <h1>Senha</h1>
                    <input value={senha} onChange={(e) => {setSenha(e.target.value)}} type='password' className='input_2'></input>

                    <h1>Confirmar senha</h1>
                    <input value={confrimaSenha} onChange={(e) => {setConfirmaSenha(e.target.value)}} type='password' className='input_2'></input>
                </div>

                <div className='flex  justify-around w-[95%] mt-3'>
                    <div className='h-full -mt-3'>
                        <div className='flex flex-col items-start gap-1'>
                            <div className='flex gap-2'>
                                <input type='checkbox' className='custom-checkbox'></input>
                                <h1>Premium Gold</h1>
                            </div>
                            <div className='flex gap-2'>
                                <input type='checkbox' className='custom-checkbox'></input>
                                <h1>Premium</h1>
                            </div>
                            <div className='flex gap-2'>
                                <input type='checkbox' className='custom-checkbox'></input>
                                <h1>Basic</h1>
                            </div>
                        </div>
                    </div>
                    <button className='botao_1 mb-10'>
                        <h1 onClick={() => {setModalCadastro(false)}} className='text-lg p-2'>Cadastrar</h1>
                    </button>
                </div>
           </div>
        </main>
    )
}

export default Dashboard;
