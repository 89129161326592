import React, { useRef, useEffect, useState, useCallback } from 'react'
import { useAtalho } from '../util/atalho'
import { FaPenNib } from 'react-icons/fa'
import { IoHandRightOutline } from 'react-icons/io5'
import { PiCursorFill } from 'react-icons/pi'
import { useLocation } from 'react-router-dom'
import { Stage, Layer, Image, Rect, Line } from 'react-konva'

const Transcricao = () => {
    const url = useLocation()

    const [modo, setModo] = useState('movimento')

    const [nivelZoom,] = useState(0.3)
    const [imagem, setImagem] = useState(null)

    const [linhas, setLinhas] = useState([])
    const [linhaAtual, setLinhaAtual] = useState([])

    const [regioes, setRegioes] = useState([    
        {
            "superior": { x:140, y: 50 },
            "inferior": { x: 750, y: 840 },
        },
        {
            "superior": { x:750, y: 50 },
            "inferior": { x: 1370, y: 920 },
        },
    ])

    const imagemRef = useRef(null)
    const stageRef = useRef(null)
    const [stage, setStage] = useState({
        scale: 1,
        x: 0,
        y: 0
      })

    useAtalho('h', () => setModo('movimento'))
    useAtalho('f', () => setModo('desenho'))

    const renderizarImagem = useCallback(() => {
        const img = new window.Image() // classe global Image para criar a imagem
        img.src = '/imagens/4129084_0001.jpg'
        img.onload = () => {
            setImagem(img)  // imagem carregada no estado
        }
    }, [])

    useEffect(() => {
        renderizarImagem()
    }, [renderizarImagem])

    const handleZoom = (e) => {
        e.evt.preventDefault()
    
        const multiplicadorZoom = 1.05
        const stage = e.target.getStage()
        const zoomAntigo = stage.scaleX()
        const posMouse = {
          x: stage.getPointerPosition().x / zoomAntigo - stage.x() / zoomAntigo,
          y: stage.getPointerPosition().y / zoomAntigo - stage.y() / zoomAntigo
        }
    
        const novoZoom = e.evt.deltaY < 0 ? zoomAntigo * multiplicadorZoom : zoomAntigo / multiplicadorZoom
    
        setStage({
          scale: novoZoom,
          x: (stage.getPointerPosition().x / novoZoom - posMouse.x) * novoZoom,
          y: (stage.getPointerPosition().y / novoZoom - posMouse.y) * novoZoom
        })
    }

    const transformCoords = (x, y) => {
        const stage = stageRef.current
        const scale = stage.scaleX()
        const stageX = stage.x()
        const stageY = stage.y()

        return {
            x: (x - stageX) / scale,
            y: (y - stageY) / scale
        }
    }

    const handleMouseDown = (event) => {
        if (modo === "desenho") {
            handleDesenhoStart(event);
        }
    };
    
    const handleMouseMove = (event) => {
        if (modo === "desenho") {
            handleDesenho(event);
        }
    };
    
    const handleMouseUp = (event) => {
        if (modo === "desenho") {
            handleDesenhoFim(event);
        }
    };

    const handleDesenhoStart = (e) => {
        const pos = e.target.getStage().getPointerPosition()
        const { x, y } = transformCoords(pos.x, pos.y)
        setLinhaAtual([x, y, x, y])
    }

    const handleDesenho = (e) => {
        if (linhaAtual.length === 0) {
            return
        }

        const pos = e.target.getStage().getPointerPosition()
        const { x, y } = transformCoords(pos.x, pos.y)
        setLinhaAtual([linhaAtual[0], linhaAtual[1], x, y])
    }

    const handleDesenhoFim = () => {
        setLinhas([...linhas, linhaAtual])
        setLinhaAtual([])
    }

    return (
        <div className='flex h-full w-full'>
            <div className='bg-gray-400 p-3 flex flex-col gap-5 z-10'>
                <button onClick={() => setModo('movimento')}><IoHandRightOutline size={30} /></button>
                <button onClick={() => setModo('controle')}><PiCursorFill size={30} /></button>
                <button onClick={() => setModo('desenho')}><FaPenNib size={30} className='rotate-90' /></button>
            </div>
            <div className='h-screen w-[70%] bg-gray-200 z-0'>
                <Stage
                    ref={stageRef}
                    width={window.innerWidth * 0.7}
                    height={window.innerHeight}
                    draggable={modo === 'movimento'}
                    onWheel={handleZoom}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    scaleX={stage.scale}
                    scaleY={stage.scale}
                    x={stage.x}
                    y={stage.y}
                    style={{
                        cursor: modo === 'movimento' ? 'grab' : modo === 'desenho' ? 'crosshair' : 'default'
                    }}
                >
                {imagem && (
                    <Layer>
                        <Image
                            image={imagem}
                            ref={imagemRef}
                            width={imagem.width * nivelZoom}
                            height={imagem.height * nivelZoom}
                        />
                        {regioes.map((regiao, index) => (
                            <Rect
                                key={index}
                                x={regiao.superior.x }
                                y={regiao.superior.y }
                                width={(regiao.inferior.x - regiao.superior.x)}
                                height={(regiao.inferior.y - regiao.superior.y)}
                                stroke={'yellow'}
                            />
                        ))}
                        {linhas.map((linha, index) => (
                            <Line
                                key={index}
                                points={linha}
                                stroke={'yellow'}
                            />
                        ))}
                        {linhaAtual.length > 0 && (
                            <Line 
                                points={linhaAtual}
                                stroke={'yellow'}
                            />
                        )}
                    </Layer>
                )}
                </Stage>
            </div>
        </div>
    )
}

export default Transcricao