import React, { useEffect, useState } from 'react'
import {  PiTrash } from 'react-icons/pi'
import { useParams } from 'react-router-dom'
import Header from '../interface/header'
import MenuT from '../interface/menuT'
import Busca from '../interface/busca'
import Pagina from '../interface/pagina'
import { FiFileText } from 'react-icons/fi'
import { LuFileInput } from 'react-icons/lu'
import { LiaFileDownloadSolid } from 'react-icons/lia'

const Paginas = () => {
    const [paginas, setPaginas] = useState([])
    const { id, nome, escrivao } = useParams()

    useEffect(() => {
        fetch('/paginas.json')
            .then(response => response.json())
            .then(data => setPaginas(data))
            .catch(error => console.error("Erro ao carregar dados:", error))
    }, [])

    return (
        <div className='flex flex-col h-[100vh]'>
            <Header perfil={true}/>
            <MenuT
                rotas={[{ nome: 'Coleções', link: '/coleções' }, { nome: nome }]}
                botoes={[
                    <Busca placeholder="Pesquise nas suas transcrições" />,
                    <button className='botao_3' title='Adicionar Páginas'>
                        <LuFileInput size={20} />
                    </button>,
                    <button className='botao_3' title='Exportar'>
                        <LiaFileDownloadSolid size={20} />
                    </button>,
                    <button className='botao_3' title='Deletar arquivos selecionados'>
                        <PiTrash size={20} />
                    </button>
                ]}
            />
            <div className='w-full px-5 pb-5 '>
                <div className='flex flex-row items-center gap-5 mt-5'>
                    <div className='flex'>
                        <FiFileText size={25} />
                        <h1 className='mt-[2px] ml-1 font-semibold'>Páginas</h1>
                    </div>
                </div>
                <div className='grid_paginas'>
                    {paginas.map(pagina => (
                        <Pagina
                            key={pagina.id}
                            id={pagina.id}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Paginas;