import { IoPersonCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Header = ({perfil}) => {
    const dados = JSON.parse(localStorage.getItem('googleUser'))
    const navigate = useNavigate()

    const logOut = () => {
        localStorage.removeItem('googleUser')
        navigate('/')
    }

    return (
        <header>
            <div className='flex flex-row items-center justify-between w-full h-20 border-b-2 border-gray-300'>
                <img src='/imagens/Transcritor.IA_logo.png' alt='logo' className='h-20' />
                {perfil &&
                    <div onClick={() => (logOut())} className="flex items-center h-full gap-3">
                        <h1 className="font-semibold">{dados?.name || 'nome'}</h1>
                        <img className=" h-[80%] mr-5 rounded-full" src={dados?.picture || 'https://styles.redditmedia.com/t5_2qh9i/styles/communityIcon_j68bk74k14o01.png'}/>
                    </div>
                } 
            </div>

        </header>
    );
};

export default Header;