import React, { useEffect, useState } from 'react'
import { PiFolder } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import Header from '../interface/header'
import MenuT from '../interface/menuT'
import Busca from '../interface/busca'
import Livro from '../interface/livro'
import { IoPersonAddOutline } from 'react-icons/io5'
import { LuFileInput } from 'react-icons/lu'

const Colecoes = () => {
    const [livros, setLivros] = useState([])
    
    const navigate = useNavigate()

    useEffect(() => {
        fetch('/livros.json')
            .then(response => response.json())
            .then(data => setLivros(data))
            .catch(error => console.error("Erro ao carregar dados:", error))
    }, [])

    return (
        <div className='flex flex-col h-[100vh]'>
            <Header perfil={true}/>
            <MenuT
                rotas={[{ nome: 'Coleções'}]}
                botoes={[<Busca placeholder="Pesquise nas suas transcrições" />]}
            />
            <div className='w-full px-5 pt-5 pb-5 '>
                <div className='flex justify-between font-bold text-xl'>
                    <h1>Bem-vindo ao Transcritor-IA.com</h1>
                    <div className='flex gap-5'>
                        <button title='Nova transcrição' className='botao_3 flex items-center justify-center'>
                            <LuFileInput size={20} />
                        </button>
                        <button title='Gerenciar Usuários' onClick={() => {navigate('/admin/contas')}} className='botao_3 flex items-center justify-center text-base'>
                            <IoPersonAddOutline size={20}/>
                        </button>
                    </div>
                </div>
                <div className='flex flex-row items-center gap-5 -mb-9'>
                    <div className='flex'>
                        <PiFolder size={25} />
                        <h1 className='mt-[2px] ml-1 font-semibold'>Coleções</h1>
                    </div>
                </div>
                <div className='grid_livro'>
                    {livros.map(livro => (
                        <Livro
                            key={livro.id}
                            id={livro.id}
                            nome={livro.nome}
                            local={livro.local}
                            periodo={livro.periodo}
                            escrivao={livro.escrivao}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Colecoes