import { useEffect } from "react"

export const useAtalho = (atalho, callback) => {
    const handleKeyDown = (event) => {
        if(atalho === event.key){
            return callback(event)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    })
}